import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
// import ReactMarkdown from 'react-markdown'
import { Container } from 'react-grid-system';

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/virtual-care-hero.jpg';
import TwoCol from '../components/rows/twocol/index';
import Follow from '../components/rows/cta/follow';
import Accordion from '../components/elements/accordion';
// import Button from '../components/elements/button'
import Cta from '../components/rows/cta';

// import ReferralPDF from '../images/PDF/pace-cardiology-referral-form-2021.pdf'

export default function Virtual({ data }) {
  return (
    <Layout>
      <Seo
        title="Virtual & Telemedicine Care"
        description="We are committed to providing our patients with exceptional care – both in person at our various clinics or 
          through safe, secure, virtual appointments. Learn how to schedule your virtual or telephone visit."
      />
      <Hero title="Virtual & Telemedicine Care" banner={Banner} />
      {data.allStrapiVirtual.edges.map(({ node }) => {
        return (
        <React.Fragment key={node}>
          <TwoCol
            key={node.virtual1.id}
            title={node.virtual1.title}
            description={node.virtual1.description}
            // link={node.virtual1.button_title}
            // href={node.virtual1.button_link}
            image={node.virtual1.image.localFile.publicURL}
          />
          <div className="medical-bg my-56 bg-gray-100 py-52 flex flex-col">
            <Container className="w-full-patch">
              {/* <ReactMarkdown children={node.res2_intro} /> */}
              <h3 className="text-center font-bold">
                <span className="font-bold text-red-300">How</span> to use PACE
                Virtual Care
              </h3>
              <Accordion data={node.virt_acc} />
            </Container>
          </div>
          <TwoCol
            key={node.virtual3.id}
            reverse
            title={node.virtual3.title}
            description={node.virtual3.description}
            link={node.virtual3.button_title}
            href={node.virtual3.button_link}
            image={node.virtual3.image.localFile.publicURL}
          />
        </React.Fragment>
      )})}
      <Cta
        title="Get in touch with PACE today!"
        button="Learn how to book"
        href="/how-to-book"
      />
      <Follow />
    </Layout>
  );
}

export const query = graphql`
  query Virtual {
    allStrapiVirtual {
      edges {
        node {
          id # id of the node
          virtual1 {
            id
            title
            description
            button_title
            button_link
            image {
              localFile {
                publicURL
              }
            }
          }
          virt_acc {
            id
            title
            description
            virtual_acc_images {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          }
          virtual3 {
            id
            title
            description
            button_title
            button_link
            image {
              localFile {
                publicURL
              }
            }
          }
          virtual4 {
            id
            title
            description
            button_title
            button_link
          }
        }
      }
    }
  }
`;
